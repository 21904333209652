<template>
  <div id="productContainer" class="product-container">
    <PowerHead />

    <router-view></router-view>
    <footer class="bg-4">
      <div class="centre">
        <div>
          <p>产品</p>
          <a href="/s/0xFuBJBLwk24OdyRVZYT">玩转Xcanvas</a>
          <a href="/s/Z5mnUZGmKMugEG20FJnd">应用场景</a>
          <a href="/s/XRK9rfuYNfUAzGGimzW5">X-er故事</a>
          <a href="/s/EeqlZ2zOJiOLPQB86t3Z">创意征集</a>
        </div>
        <div>
          <p>公司</p>
          <a href="javascipt:;">Hiten</a>
          <a href="javascipt:;">媒体报道</a>
          <a href="javascipt:;">企业文化</a>
          <a href="javascipt:;">联系我们</a>
        </div>
        <div>
          <p>资源</p>
          <a href="javascipt:;">商业案例</a>
          <a href="javascipt:;">合作伙伴</a>
          <a href="javascipt:;">使用文档</a>
          <a href="javascipt:;">API&amp;SDK</a>
        </div>
        <div>
          <p>链接</p>
          <a href="javascipt:;">公众号</a>
          <a href="javascipt:;">微博</a>
          <a href="javascipt:;">视频</a>
          <a href="javascipt:;">博客</a>
        </div>
        <p style="clear: both"></p>
        <p class="icp">
          Copyright 2021 北京太空漫步科技有限公司 版权所有
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备18063286号-6</a
          >
        </p>
        <p class="icp2">
          Copyright 2021 北京太空漫步科技有限公司 版权所有<br /><a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >京ICP备18063286号-6</a
          >
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import PowerHead from "@components/layout/PowerHead";

export default {
  name: "Product",
  components: { PowerHead },
  data() {
    return {
      liOn: false
    };
  }
};
</script>

<style lang="scss">
.product-container {
  a {
    color: #666;
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
    color: #ff4a00;
    transition: all 0.3s ease-in-out;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }

  input,
  button,
  select,
  textarea {
    outline: none;
    border: none;
    font-size: 14px;
    font-family: inherit;
    -webkit-appearance: none;
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  em,
  i {
    font-style: normal;
  }

  img {
    border: none;
    vertical-align: middle;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .cb:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  body,
  html {
    background: #f5f5f5;
    width: 100%;
  }
}
#productContainer {
  min-height: 100vh;
  font-size: 12px;
  font-family: "Microsoft YaHei", sans-serif;
  color: #666;

  .center_main {
    width: 1200px;
    margin: auto;
    margin-bottom: 50px;

    .list-nav-tab {
      border-bottom: #dfdfdf solid 1px;
      height: 55px;
      line-height: 55px;
      padding-top: 25px;
      font-size: 26px;
      color: #333;
    }

    .list-nav-tab span {
      display: inline-block;
      margin-right: 70px;
      cursor: pointer;
    }

    .list-nav-tab span.hot,
    .list-nav-tab span:hover {
      border-bottom: #f99000 solid 4px;
      height: 53px;
      line-height: 55px;
    }

    .por {
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        display: block;
      }
      .h3-box {
        position: absolute;
        width: 100%;
        height: 70px;
        line-height: 70px;
        background: rgba(0, 0, 0, 0.5);
        left: 0;
        bottom: 0;
        h3 {
          font-size: 26px;
          color: #fff;
          margin-left: 30px;
          float: left;
        }
        span {
          float: right;
          background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/yuedu.png)
            no-repeat 0 center;
          padding-left: 34px;
          background-size: 25px;
          margin-right: 30px;
          font-size: 20px;
          color: #999;
        }
      }
    }

    .mt40 {
      margin-top: 40px;
    }

    .img-box-2 {
      width: 804px;
      float: left;
    }

    .img-box-2-2 {
      width: 384px;
      float: right;
    }

    .mt22 {
      margin-top: 22px;
    }

    .img-box-3 {
      width: 103%;
      & > .por {
        float: left;
        width: 380px;
        margin-right: 30px;
      }
    }
  }

  /*首页*/
  .index-banner {
    background: #2f2f2f;
    min-height: 655px;
    text-align: center;
    position: relative;

    & > h2 {
      font-size: 48px;
      height: 50px;
      line-height: 50px;
      color: #fff;
      padding-top: 85px;
    }

    & > h3 {
      font-size: 38px;
      color: #fff;
      height: 40px;
      line-height: 40px;
      padding-top: 25px;
    }
    & > .index-pay {
      position: absolute;
      width: 1200px;
      left: 50%;
      margin-left: -600px;
      top: 300px;
      cursor: pointer;
    }
  }

  .ljitiyan {
    width: 180px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: auto;
    display: block;
    border-radius: 50px;
    background: #f99000;
    font-size: 20px;
    color: #fff;
    margin-top: 49px;
    &:hover {
      color: #fff;
      opacity: 0.8;
    }
  }

  .index-ditu img {
    display: block;
    width: 100%;
  }

  .sheishuo {
    font-size: 48px;
    color: #333;
    text-align: center;
    padding-top: 420px;
    height: 50px;
    line-height: 50px;
  }

  .index-box {
    width: 1200px;
    margin: auto;
  }

  .tupian-box img {
    width: 660px;
  }

  .wenzi-box {
    width: 500px;

    h3 {
      font-size: 48px;
      color: #333;
      height: 50px;
      line-height: 50px;
      margin-top: 110px;
    }

    p {
      font-size: 28px;
      color: #999;
      height: 35px;
      line-height: 35px;
      margin-top: 20px;
    }
  }

  .mt80 {
    margin-top: 80px;
  }

  .zuobian {
    text-align: right;
  }

  .shipin {
    position: fixed;
    width: 1200px;
    height: 640px;
    left: 50%;
    top: 50%;
    margin-top: -320px;
    margin-left: -600px;
  }

  .alertxxx {
    position: absolute;
    right: -35px;
    top: -35px;
    margin: 0;
    width: 50px;
    padding: 0;
    height: 50px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/canvas_ic_w.png)
      no-repeat center center;
    background-size: 40px !important;
    cursor: pointer;
  }

  .index-lunbo-box {
    height: 708px;
    background: #f8f8f8;
  }

  .index-lunbo-box .w1200 {
    width: 1200px;
    margin: auto;
    overflow: hidden;
    padding: 0 90px 0;
  }

  .por {
    position: relative;
  }

  .main_image2 {
    position: relative;
    height: 350px;
    margin-bottom: 140px;
    overflow: hidden;
    width: 1200px;
    margin: 180px auto 0;
  }

  .main_image2 .swiper-slide > div {
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_03.png)
      repeat-x #e9f5fb;
    width: 275px;
    height: 370px;
    float: left;
    margin-right: 35px;
    border-radius: 5px;
  }
  .main_image2 .swiper-slide > div:nth-child(2n + 0) {
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_05.png)
      repeat-x;
    width: 275px;
    height: 370px;
    float: left;
    margin-right: 30px;
    border-radius: 5px;
  }
  .main_image_img {
    text-align: center;
    display: block;
    line-height: 120px;
  }

  .main_image_wenzi {
    height: 95px;
    line-height: 100px;
    font-size: 30px;
    color: #666;
    text-align: center;
  }
  .newjianjie {
    width: 240px;
    margin: auto;
    display: block;
    font-size: 14px;
    color: #333;
    line-height: 20px;
    max-height: 120px;
    overflow: hidden;
  }

  .main_image2 .swiper-slide > div:nth-child(4) {
    margin-right: 0px;
  }

  #btn_prev2,
  #btn_next2 {
    position: absolute;
    top: 50%;
    z-index: 2;
    font-size: 0;
    cursor: pointer;
    width: 85px;
    height: 85px;
    margin-top: 40px;
  }
  #btn_prev2 {
    left: 2px;
    right: auto;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_22.png)
      no-repeat center center;
  }

  #btn_next2 {
    right: 2px;
    left: auto;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/new_25.png)
      no-repeat center center;
  }

  .bg-4 {
    background-color: #2f2f2f;
    color: #fff;
  }

  .centre {
    width: 1200px;
    margin: auto;
    height: 330px;
    font-size: 16px;
  }

  .centre > div {
    width: 25%;
    text-align: center;
    float: left;
  }

  .centre > div p {
    margin-top: 50px;
    color: #ececec;
    margin-bottom: 28px;
  }

  .centre > div a {
    color: #999;
    display: block;
    margin-bottom: 16px;
  }

  .centre > div a:hover {
    color: #e87722;
  }

  .icp,
  .icp2 {
    color: #999;
    font-size: 12px;
    text-align: center;
    margin-top: 25px;
  }

  .bgfff {
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/Rectangle6.png)
      no-repeat center center #fff;
    -webkit-background-size: 100%;
    background-size: 100%;
  }

  #shipin {
    width: 1200px;
    height: 640px;
  }

  .xinshou {
    position: fixed;
    right: 30px;
    bottom: 100px;
    z-index: 77;
  }

  .xinshou img {
    display: block;
    width: 202px;
  }

  /*首页*/

  /*直播*/
  .zhibo-box {
    background: #222;
    padding-bottom: 100px;
  }

  .zhibo-box .w1200 {
    width: 1200px;
    margin: auto;
  }

  .zhibo-box .guangfang {
    font-size: 18px;
    color: #fff;
    height: 30px;
    line-height: 30px;
    padding-top: 40px;
  }

  .zhibo-box .guangfang img {
    margin-right: 10px;
  }

  .zhibo-box .zhibo_h2 {
    font-size: 24px;
    color: #fff;
    height: 65px;
    line-height: 65px;
    overflow: hidden;
  }

  .zhibo-box .zhibo_h2 .zhuantao {
    font-size: 14px;
    color: #fff;
    display: inline-block;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
    margin-left: 20px;
  }

  .zhibo-box .zhibo_h2 .zhuantao.hot {
    background: #f54343;
  }

  .zhibo-box .zhibo_h2 .yuedushu {
    float: right;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/yuedu.png)
      no-repeat 0 center;
    padding-left: 34px;
    background-size: 25px;
    margin-right: 30px;
    font-size: 18px;
    color: #fff;
  }

  .zhibo-box .bofanxingxin {
    position: relative;
  }

  .zhibo-box .bofanxingxin .zhuti-banner {
    width: 100%;
    display: block;
  }

  .zhibo-box .bofanxingxin .play {
    display: block;
    width: 158px;
    height: 158px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -79px;
    margin-top: -79px;
    cursor: pointer;
  }

  .zhibo-box .bofanxingxin .play:hover {
    opacity: 0.9;
  }

  .zhibo-box .bofanxingxin .zhibodaojishi {
    padding: 0 50px;
    height: 75px;
    line-height: 75px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    font-size: 32px;
    color: #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25%;
    margin-top: -37px;
    cursor: pointer;
  }

  /*直播*/

  /*在家*/
  .zaijiabangong {
    background: #010011;
  }

  .banner {
    position: relative;
  }

  .banner img {
    width: 100%;
    display: block;
  }

  .banner .baomingcanjia {
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -113px;
    width: 225px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 32px;
    color: #fff;
    background: #f99000;
    border-radius: 60px;
    cursor: pointer;
  }

  .zjbg-w1200 {
    width: 1200px;
    margin: auto;
    padding-bottom: 100px;
  }

  .zjbg-w1200 h1 {
    font-size: 48px;
    font-weight: 700;
    color: #fff;
    line-height: 100px;
    height: 100px;
  }

  .zjbg-w1200 h1 span {
    color: #37beff;
    margin-right: 30px;
  }

  .zjbg-w1200 h1 i {
    font-weight: 400;
    font-size: 20px;
    margin-left: 30px;
  }

  .zjbg-w1200 .zhu-jiejian {
    font-size: 20px;
    color: #fff;
    line-height: 38px;
  }

  .zjbg-w1200 .zhu-jiejian span {
    color: #37beff;
  }

  .zjbg-w1200 .zhu-jiejian i {
    color: #f5a623;
  }

  .zhaopian-box .shijian {
    border-bottom: #333 solid 2px;
    font-size: 36px;
    color: #fff;
    height: 60px;
    line-height: 60px;
    margin-top: 65px;
  }

  .zhaopian-box .shijian span {
    display: inline-block;
    width: 144px;
    border-bottom: #f99000 solid 4px;
    height: 58px;
    line-height: 60px;
  }

  .zhaopian-ulli-box {
    width: 106%;
  }

  .zhaopian-ulli-box > div {
    width: 360px;
    margin-right: 59px;
    float: left;
    padding-top: 40px;
  }

  .zhaopian-ulli-box > div .img-box {
    width: 360px;
    height: 201px;
    overflow: hidden;
    position: relative;
  }

  .zhaopian-ulli-box > div .img-box img {
    width: 100%;
    min-height: 201px;
  }

  .zhaopian-ulli-box > div .img-box .index-pay {
    width: 182px;
    height: 182px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -90px;
    margin-left: -90px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zbply.png)
      no-repeat center center;
    display: block;
    cursor: pointer;
  }

  .zhaopian-ulli-box > div h2 {
    font-size: 20px;
    color: #fff;
    height: 28px;
    line-height: 28px;
    padding-top: 10px;
    overflow: hidden;
  }

  .zhaopian-ulli-box > div p {
    font-size: 12px;
    color: #fff;
    height: 17px;
    line-height: 17px;
    padding-top: 10px;
  }

  .zhaopian-ulli-box > div .img-box .index-pay:hover,
  .chakangengduo:hover,
  .banner .baomingcanjia:hover,
  .tijiao-but:hover,
  .zhaopian-ulli-box > div .img-box .index-pay2:hover {
    opacity: 0.9;
  }

  .chakangengduo {
    width: 225px;
    height: 60px;
    line-height: 60px;
    border-radius: 60px;
    border: #fff solid 1px;
    text-align: center;
    margin: 50px auto 0;
    font-size: 26px;
    color: #fff;
    cursor: pointer;
  }

  .chakangengduo img {
    margin-left: 10px;
  }

  .tijiao-but {
    font-size: 36px;
    color: #fff;
    width: 400px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    background: #f99000;
    border-radius: 80px;
    display: block;
    margin: auto;
    margin-top: 50px;
    cursor: pointer;
  }

  .tupian_center {
    display: none;
  }

  .heise {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .tupian_center_box {
    width: 1110px;
    height: 800px;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -555px;
    margin-top: -400px;
    z-index: 9999;
  }

  .zhu-img {
    width: 1110px;
    height: 619px;
    overflow: hidden;
  }

  .zhu-img img {
    width: 100%;
    display: block;
    min-height: 619px;
  }

  .xiao-img {
    width: 105%;
  }

  .xiao-img span {
    width: 255px;
    height: 143px;
    padding-top: 38px;
    margin-right: 30px;
    display: block;
    float: left;
    overflow: hidden;
    cursor: pointer;
  }

  .xiao-img span img {
    width: 100%;
    display: block;
    min-height: 143px;
    pointer-events: none;
  }

  .xiao-img span.on {
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/hjt.png)
      no-repeat 108px 12px;
  }

  .xiao-img span.on img {
    border: #f6bc02 solid 4px;
    width: 248px;
    height: 135px;
    min-height: 135px;
  }

  #focus {
    width: 1110px;
    height: 619px;
    overflow: hidden;
    position: relative;
  }

  #focus ul {
    height: 619px;
    position: absolute;
  }

  #focus ul li {
    float: left;
    width: 1110px;
    height: 619px;
    overflow: hidden;
    position: relative;
  }

  #focus ul li a,
  #focus ul li a img {
    display: block;
    position: relative;
  }

  #focus ul li a p {
    position: absolute;
    bottom: 0;
    width: 95%;
    height: 60px;
    line-height: 60px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/lanbo.png)
      no-repeat center center;
    color: #fff;
    font-size: 24px;
    padding-top: 80px;
    padding-left: 5%;
  }

  .pre {
    width: 88px;
    height: 64px;
    margin-top: -32px;
    position: absolute;
    top: 50%;
    left: 20px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/you.png)
      no-repeat;
    z-index: 9999;
    display: none;
  }

  .next {
    width: 88px;
    height: 64px;
    position: absolute;
    top: 50%;
    margin-top: -32px;
    right: 20px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zuo.png)
      no-repeat;
    z-index: 9999;
    display: none;
  }

  .zhu-img:hover .next,
  .zhu-img:hover .pre {
    display: block;
  }

  /*在家*/
  .piaofu {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    background: #fff;
  }

  .alertxxx {
    position: absolute;
    right: -35px;
    top: -35px;
    margin: 0;
    width: 50px;
    padding: 0;
    height: 50px;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/canvas_ic_w.png)
      no-repeat center center;
    background-size: 40px !important;
    cursor: pointer;
  }

  #shipin {
    width: 100%;
    height: 100%;
  }

  .piaofu2 {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
    top: 0;
    left: 0;
  }

  .shipin2 {
    position: fixed;
    width: 1200px;
    height: 640px;
    left: 50%;
    top: 50%;
    margin-top: -320px;
    margin-left: -600px;
    background: #fff;
  }

  .index-banner > .index-pay-box {
    position: absolute;
    width: 1200px;
    left: 50%;
    margin-left: -600px;
    top: 300px;
    cursor: pointer;
    margin-top: 60px;
  }

  .index-banner > .index-pay-box img {
    width: 100%;
  }

  .zhaopian-ulli-box > div .img-box .index-pay2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    display: block;
    cursor: pointer;
    background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/zbply.png)
      center center/100px no-repeat;
  }

  .icp2 {
    display: none;
  }
  .wap-ljitiyan,
  .wap-zhangduan {
    display: none;
  }

  @media screen and (max-width: 1100px) {
    .header-box .nav-tab {
      display: block;
    }
    .wap-ljitiyan,
    .wap-zhangduan {
      display: block;
    }
    .pc-ljitiyan,
    .duoduanxiazai,
    .zhibo-box .guangfang {
      display: none !important;
    }

    .header-box .logo-nav .nav {
      display: none;
    }

    .header-box .login_box {
      display: none;
    }

    .center_main {
      width: 100%;
    }

    .list-nav-tab span {
      margin-right: 0 !important;
      width: 30%;
      text-align: center;
      font-size: 20px;
    }

    .center_main .img-box-2 {
      width: 100%;
      float: left;
    }

    .center_main .img-box-2-2 {
      width: 100%;
      float: right;
      margin-top: 40px;
    }

    .center_main .mt22 {
      margin-top: 40px;
    }

    .center_main .img-box-3 > .por {
      width: 100%;
      margin-bottom: 40px;
    }

    .center_main .img-box-3 {
      width: 100%;
    }

    .duoduanxiazia_box {
      position: fixed;
      width: 100%;
      height: 100%;
      background: #f4f4f4;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.7);
      top: 70px;
      left: 0%;
      margin-left: 0px;
      border-radius: 0px;
    }

    .duoduanxiazia_box_center {
      width: 100%;
      margin: auto;
      height: 100%;
      overflow: auto;
    }

    #btn_prev2 {
      right: 18px;
    }

    #btn_next2 {
      left: 18px;
    }

    .index-lunbo-box {
      display: none;
    }

    .tupian-box,
    .index-box,
    .centre {
      width: 100%;
      height: auto;
    }

    .tupian-box img {
      width: 100% !important;
    }

    .index-banner > .index-pay-box {
      position: relative;
      width: 92%;
      left: 0;
      margin: 0 4%;
      top: 0px;
      cursor: pointer;
      margin-top: 60px;
    }

    .xinshou img {
      display: block;
      width: 50px;
    }

    .sheishuo {
      padding-top: 50px;
    }

    .index-banner {
      min-height: auto;
      padding-bottom: 15px;
    }

    .center_main,
    .zhibo-box .w1200,
    .zjbg-w1200 {
      width: 92%;
      margin: auto;
      padding: 0 4%;
    }

    .zhibo-box .zhibo_h2 {
      font-size: 20px;
      padding-top: 20px;
    }

    .alertxxx {
      right: 0;
      top: -50px;
      background: url(https://5gcoolwork.oss-cn-beijing.aliyuncs.com/images/product/canvas_ic_w.png)
        center center no-repeat #999;
    }

    .zhibo-box .bofanxingxin .play {
      width: 100px;
      height: 100px;
      margin-left: -50px;
      margin-top: -50px;
    }

    .zhibo-box .bofanxingxin .zhibodaojishi {
      height: 50px;
      width: 190px;
      line-height: 50px;
      font-size: 12px;
      margin-left: 0;
      margin-top: 0;
      transform: translate(-50%, -50%);
      padding: 0 17px;
    }

    .zjbg-w1200 {
      padding-bottom: 50px;
    }

    .banner .baomingcanjia {
      bottom: 6px;
      margin-left: 0px;
      width: 140px;
      height: 30px;
      line-height: 30px;
      font-size: 15px;
      display: block;
      margin: auto;
      position: initial;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .zjbg-w1200 h1 {
      font-size: 20px;
      line-height: 40px;
      height: inherit;
    }

    .zjbg-w1200 .zhu-jiejian {
      font-size: 12px;
      color: #fff;
      line-height: 28px;
    }

    .zhaopian-box .shijian {
      margin-top: 20px;
      font-size: 30px;
    }

    .zhaopian-ulli-box > div {
      width: 100%;
      margin-right: 0;
      float: left;
      padding-top: 40px;
    }

    .zhaopian-ulli-box {
      width: 100%;
    }

    .zhaopian-ulli-box > div .img-box {
      width: 100%;
      height: inherit;
      position: relative;
      overflow: hidden;
    }

    .shipin2 {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      transform: translate(-50%, -50%);
      height: auto;
    }

    .chakangengduo {
      width: 150px;
      height: 40px;
      line-height: 40px;
      border-radius: 60px;
      border: #fff solid 1px;
      text-align: center;
      margin: 50px auto 0;
      font-size: 16px;
      color: #fff;
      cursor: pointer;
    }

    .chakangengduo img {
      width: 18px;
    }

    .center_main .por .h3-box h3 {
      font-size: 20px;
    }

    .center_main .por .h3-box span {
      font-size: 16px;
      background-size: 20px;
      padding-left: 28px;
    }

    .center_main .por .h3-box {
      height: 50px;
      line-height: 50px;
    }

    .centre > div,
    .icp {
      display: none;
    }

    .icp2 {
      line-height: 25px;
      padding: 10px 0px;
      display: block;
      margin-top: 0px;
    }

    .zjbg-w1200 h1 span {
      color: #37beff;
      margin-right: 30px;
      display: block;
    }

    .wenzi-box {
      width: 100% !important;
      text-align: center;
    }

    .sheishuo {
      font-size: 19px;
      padding-top: 40px;
    }

    .wenzi-box h3 {
      font-size: 24px;
      color: #333;
      height: 30px;
      line-height: 30px;
      margin-top: 45px;
    }

    .wenzi-box p {
      font-size: 14px;
      color: #999;
      height: 35px;
      line-height: 35px;
      margin-top: 0px;
    }

    .mt80 {
      margin-top: 0px;
    }
    #__app {
      display: none !important;
    }

    .zhaopian-ulli-box > div h2 {
      padding-top: 18px;
    }

    .tupian_center_box {
      width: 0%;
      height: 0%;
      border-radius: 5px;
      position: fixed;
      top: 0%;
      left: 0%;
      margin-left: 0px;
      margin-top: 0px;
      z-index: 9999;
    }

    #focus {
      display: none;
    }

    .xiao-img {
      position: fixed;
      width: 96%;
      padding: 2% 2%;
      background: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .xiao-img span {
      width: 46%;
      padding-top: 0px;
      margin-right: 0px;
      display: block;
      float: left;
      height: auto;
      overflow: hidden;
      margin: 2%;
    }

    .xiao-img span.on {
      background: rgba(0, 0, 0, 0);
    }

    .xiao-img span.on img {
      border: #f6bc02 solid 0px;
      width: 100%;
      height: auto;
      min-height: auto;
    }
    .zhaopian-ulli-box > div .img-box img {
      width: 100%;
      display: block;
      min-height: auto;
    }
  }

  @media screen and (max-width: 740px) {
    .duoduanxiazia_box_center ul li {
      width: 50%;
      height: auto;
      margin: auto;
    }

    .erwmsm,
    .erwma {
      margin: 23px auto 0px;
      width: 80%;
      height: auto;
    }

    .index-banner h2 {
      font-size: 20px;
      padding-top: 40px;
    }

    .index-banner h3 {
      font-size: 18px;
      padding-top: 0px;
    }

    .erwma > img {
      padding-top: 10px;
    }

    .erwma p {
      height: 40px;
      line-height: 28px;
      margin-top: 10px;
    }

    .erwmsm img {
      width: 40px;
    }
  }

  @media screen and (max-width: 1280px) {
    #btn_next2 {
      left: 0%;
    }

    #btn_prev2 {
      right: 0%;
    }

    #btn_next2,
    #btn_prev2 {
      display: none;
    }

    .por:hover #btn_next2,
    .por:hover #btn_prev2 {
      display: block;
    }
  }
}
</style>
